
import PromiseBunny from "../images/bunnypromise.png"
import BoldName from "../components/mobile/boldname";
import Mission from "../images/mission.png"
function LandingPage() {
  
    return (
      <div class="w-full bg-yellow-300">
          <div class="w-full h-8"></div>
        <div class="w-full text-xl flex gap-3 justify-center flex-row">
            <div id="" class="text-3xl atoms_title">ATOMS</div>    
        </div>
        <div class="w-full text-blue-600 text-center p-4 pb-3 text-xl">STAR PRODUCT DESIGNER</div>
        <div class="w-full text-blue-600 text-center pb-3 px-12">This is not about design, UX, UI but about architecturing a product</div>
        <div class="w-full text-center px-4  text-4xl mt-6">only 10$</div>
        <div class="w-full text-center px-4 text-xs font-bold mb-6">DM on <a class="underline" target="_blank" rel="noreferrer" href="https://www.twitter.com/eliusthebot">twitter</a> to get a 5$ link</div>
        <div class="w-full  text-base font-light mb-4"><div class="w-11/12 mx-auto"><img src={Mission}/></div></div>
        <div class="w-full text-blue-600 text-2xl font-bold pb-3"><div class="w-8/12 text-center mx-auto">SUPREME  BUILDING BLOCKS OF A REAL PRODUCT COMPANY</div></div>
        <div class="w-full p-4"><img class="px-16 mx-auto" alt="bunny promise" src={PromiseBunny}></img></div>
        <div class="w-full my-4">
            <div class="mx-auto border-2 font-bold rounded-lg text-lg border-blue-600 bg-gray-100 w-10/12 p-6">
                <p class="text-black">Signup to Dashboard</p>
                <p class="text-green-600">(it's free)</p>
                <p class="text-blue-600">BELOW IS THE CONTENT YOU GET</p>
            </div>
        </div>
        <div>
          <br/>
        <br/>
        <BoldName class="font-bold" title="LIST OF METHODS" />
        </div>
        <br/>
        <BoldName title="Risks" color="text-blue-500"/>
        <BoldName title="If Differentiation needed from Broader Concepts"/>
        <BoldName title="Integration needed if in Specific Concepts"/>
        <br/>
        <BoldName title="Ideation" color="text-blue-500"/>
        <BoldName title="The Problem Space Picture"/>
        <BoldName title="Identification of the First Solution"/>
        <BoldName title="Identification of Market Needed Ancillary Solutions"/>
        <br/>
        <BoldName title="Offering" color="text-blue-500"/>
        <BoldName title="Decision on Choice of Capability (Medium based)"/>
        <BoldName title="The Expected Core Consumer Behaviour Model"/>
        <BoldName title="The Inspired Use Model of each Feature"/>
        <br/>
        <BoldName title="Channel" color="text-blue-500"/>
        <BoldName title="The Strategy of your First Channel"/>
        <BoldName title="Content for your First Channel"/>

        <div class="w-full h-20"></div>
        <div className="w-full text-center"> &#169; 123 VENTURES</div>

        <div className="h-4"></div>
        <div className="h-4"></div>

      </div>
    );
  }
  
  export default LandingPage;
  