

function BoldName({title, color="text-black"}) {
  
    return (
      <div class={`${color} text-xl font-light px-8 p-1 w-full`}>
        {title}
      </div>
    );
  }
  
  export default BoldName;
  